// Used for old User inside company typehead/bloodhound
// to remove with autocomplete_user_typehead
import useFeatureFlag from 'hb-react/shared/hooks/useFeatureFlag';
import { htmlSafe } from 'helpers/htmlSafe';

const UserAutocompleteTemplate = ({
  full_name: fullName,
  headline,
  photo_url: photoUrl,
  new_photo: newPhoto,
}) => {
  const { flagEvUserPicturesToGlobalStorage } = useFeatureFlag();

  return (
    <div className="muted-link rounded search-item soft-half">
      <div className="flexbox">
        <div className="flexbox__item v-middle">
          <img
            alt=""
            className="block"
            height="42px"
            src={
              flagEvUserPicturesToGlobalStorage
                ? newPhoto?.thumbnail_url
                : photoUrl
            }
            width="42px"
          />
        </div>
        <div className="flexbox__item ten-twelfths soft-half--left v-middle">
          <div className="weight--bold">
            {fullName}
            {headline &&
              (headline.length > 80 ? (
                <div className="color-gray">
                  {htmlSafe(headline.substr(0, 80))}...
                </div>
              ) : (
                <div className="color-gray">{htmlSafe(headline)}...</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAutocompleteTemplate;
